<template>
  <div class="SetLine">
    <div class="flex">
      <div class="df-item" :class="onSelectType==0?'active':''" @click="onSelectType = 0">服务商</div>
      <div class="df-item" :class="onSelectType==1?'active':''" @click="onSelectType = 1">线路材料配置</div>
    </div>
    <el-divider style="margin:12px 0 24px;"></el-divider>
    <div class="flex-nowrap-space-between mb-20" v-if="!onSelectType">
      <div class="flex-nowrap-space-around">
        <el-radio-group v-model="searchForm.mode" size="small" @change="setTimeData">
          <el-radio-button label="0">
            <span class="type-name">AXB模式</span>
          </el-radio-button>
          <el-radio-button label="1">
            <span class="type-name">回拨模式</span>
          </el-radio-button>
          <el-radio-button label="2">
            <span class="type-name">SIP线路</span>
          </el-radio-button>
          <el-radio-button label="3">
            <span class="type-name">资料审核</span>
          </el-radio-button>
        </el-radio-group>
        <div class="ml-20">
          <el-button size="small" @click="editAmount()">
            <icon-font type="icon-tianjia" class="font-refresh-16" />
            添加服务商
          </el-button>
        </div>
      </div>
      <div>
        <!-- <el-pagination background layout="prev, pager, next" :total="total"  @current-change="handleCurrentChange">
                 </el-pagination> -->
      </div>
    </div>
    <div class="configtext" v-if="onSelectType">线路材料配置</div>
    <el-button size="small" class="append-margin" v-if="onSelectType" @click="appendFrom">
      <icon-font type="icon-tianjia" class="font-refresh-16" />
      添加材料
    </el-button>
    <el-table :data="tablelist" v-if="onSelectType" header-row-class-name="header-row"
      style="width: 100%;margin-top:10px;">
      <el-table-column prop="formName" label="材料名称" />
      <el-table-column prop="formType" label="类型">
        <template #default="scope">
          {{itemTypeList[scope.row.formType]||scope.row.formType}}
        </template>
      </el-table-column>
      <el-table-column prop="uploadRule" label="上传规则" />
      <el-table-column label="是否复用" width="100">
        <template #default="scope">
          <el-switch active-color="#637DFF" :active-value="0" :inactive-value="1" v-model="scope.row.reuse"
            @change="isReuse(scope)" @click="isReuseC" inactive-color="#BFBFBF"></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="100">
        <template #default="scope">
          <el-button type="text" class="button-text-edit" @click="editForm(scope)">编辑</el-button>
          <el-popconfirm @confirm="deleteForm(scope.row.id)" title="请确认删除" confirm-button-text="确定"
            cancel-button-text="取消">
            <template #reference>
              <el-button type="text" class="button-text-del">删除</el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <div class="flex-wrap-space-around lineCard-show-box" v-loading="loading" id="scroll" ref="scrollTopList"
      v-if="!onSelectType">
      <lineCard v-for="(line,l) in lineList" :key="l" :lineData="line" :modemaap="modemaap" @addSuccess='itemAddSuccess'
        type="set" :status="line.status == 0?'ok':'err'">
        <template v-slot:left='ctx'>
          <el-popconfirm confirm-button-text="确认" cancel-button-text="取消" icon-color="red" title="是否确认删除该服务商?"
            @confirm="confirmEvent" @cancel="cancelEvent">
            <template #reference>
              <el-button size="medium" round class="button-left" @click="deleteAmount(ctx.data)">删除</el-button>
            </template>
          </el-popconfirm>

        </template>
        <template v-slot:right='ctx'>
          <el-button size="medium" round class="button-right" @click="editAmount(ctx.data)">编辑</el-button>
        </template>
      </lineCard>
    </div>
    <div>
      <el-dialog v-model="centerDialogVisible" :title="linePopupType == 'set'? '添加服务商' : '修改服务商' "
        :close-on-click-modal="false" :destroy-on-close="true" width="600px">
        <linePopup @addSuccess='itemAddSuccess' @resetForm='centerDialogVisible = false' :type="linePopupType">
        </linePopup>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { IconFont } from '@/utils/iconfont'
import lineCard from './lineComponent/lineCard.vue' //分页item
import linePopup from './lineComponent/linePopup.vue' //新增弹框
import amount from '@/api/open/amount/amount'
import dictionary from '@/api/system/dictionary'
import { queryList, updateReuse, deleteForm } from '@/api/config/config'
import { getToken } from '@/plugins/cookie'
export default {
  name: 'SetLine',
  components: {
    IconFont,
    lineCard,
    linePopup,
  },
  data() {
    return {
      onSelectType: 0,
      type: 1,
      searchForm: {
        page: 1,
        pageSize: 52,
        mode: '0',
        sortBy: ['createTime'],
        sortDesc: ['false'],
      },
      lineList: [],
      linePopupType: 'set',
      modemaap: {},
      total: 0,
      loading: true,
      centerDialogVisible: false,
      amountId: null,
      currentPage: 1, // 分页
      search_Form: {
        // 搜索项
        name: '', // 接口名称
        remark: '', // 备注
        options: '', // 接口配置
        createTimeBegin: '', // 创建时间--开始
        createTimeEnd: '', // 创建时间--结束
        status: '', // 接口状态(0:正常 1:禁用)
        beanName: '', // 接口实现类名
        page: 1,
        pageSize: 20,
      },
      tablelist: [],
      itemTypeList: {
        img: '图片',
        video: '视频',
        input: '输入框',
        textarea: '文本域',
        radio: '单选',
        switch: '滑块',
        checkbox: '多选',
        select: '下拉选择',
        upload: '上传',
        date: '日期',
        cascader: '级联',
        mobileTextarea: '电话文本域',
        word: '文档',
        excel: '表格',
        industry: '所属行业',
      },
    }
  },
  mounted() {
    // let list = JSON.parse(localStorage['formlist'] || '[]')
    // this.tablelist = list.map((e) => {
    //   return JSON.parse(localStorage[e])
    // })

    // console.log(this.$route.params);
    if (this.$route.query.reline) {
      this.onSelectType = 1
    }
    this.getModeList()
    this.getAmounttsList()
    this.queryList()
    // 监听滚动事件
    this.divScrollChange()
  },
  methods: {
    deleteForm(id) {
      const loading = this.$loading({
        title: '删除中',
      })
      deleteForm({ id })
        .then((res) => {
          loading.close()
          if (res.code == 200) {
            this.$notify.success('删除成功')
            this.queryList()
          }
        })
        .catch((err) => {
          loading.close()
          this.queryList()
          console.log(err)
        })
    },
    isReuse(scope) {
      const loading = this.$loading({
        title: '修改中',
      })
      updateReuse({ id: scope.row.id, reuse: scope.row.reuse })
        .then((res) => {
          loading.close()
          if (res.code == 200) {
            this.$notify.success('修改成功')
            this.queryList()
          }
        })
        .catch((err) => {
          loading.close()
          this.tablelist[scope.$index].reuse = this.tablelist[scope.$index]
            .reuse
            ? 0
            : 1
          this.$forceUpdate()
          console.log(err)
        })
    },
    editForm(scope) {
      let id = scope.row.id
      this.$router.push({ path: '/line/config', query: { id } })
    },
    //表单列表
    queryList() {
      queryList().then((res) => {
        if (res.code == 200) {
          this.tablelist = res.data
        }
      })
    },
    appendFrom() {
      this.$router.push({ path: '/line/config' })
    },
    // 分页--条数
    handleSizeChange(val) {
      this.search_Form.pageSize = val
      this.getDataList()
    },
    // 分页--页码
    handleCurrentChange1(val) {
      this.search_Form.page = val
      this.currentPage = val
      this.getDataList()
    },
    getDataList() {},
    getAmounttsList() {
      this.loading = true
      amount.amountList({ ...this.searchForm }).then((res) => {
        this.lineList = res.data.records
        this.total = res.data.total
        this.loading = false
      })
    },
    handleCurrentChange(val) {
      this.searchForm.page = val
      this.getAmounttsList()
    },
    setTimeData() {
      this.getAmounttsList()
    },
    // 服务商添加成功
    itemAddSuccess(val) {
      this.centerDialogVisible = false
      this.getAmounttsList()
    },
    // 获取线路模块表
    getModeList() {
      dictionary
        .queryName({
          type: 'mode',
        })
        .then((res) => {
          res.data.forEach((mode, m) => {
            this.modemaap[mode.code] = mode.remarks
          })
        })
    },
    // 删除服务商
    deleteAmount(data) {


      // this.linePopupType = data.id
    //   this.centerDialogVisible = true
      this.amountId = data.id
    },
    // 删除确认
    confirmEvent() {
      console.log(123)
      amount
        .amountDelete({
          id: this.amountId,
        })
        .then((res) => {
          this.$notify({
            message: '服务商删除成功！',
            type: 'success',
          })
          this.getAmounttsList()
        })
    },
    cancelEvent() {
      console.log('cancel!')
    },
    // 编辑服务商

        editAmount(e) {
          if(e){
  let id = e.id
      // console.log(this.lineData);
      this.$router.push({
        path: '/line/editli',
        query: { id },
      })
          }
          else{
             this.$router.push({
        path: '/line/editli',
        query: { id:'set' },
      })
          }
         
    },
    // editAmount(data) {
    //   this.linePopupType = data.id
    //   this.centerDialogVisible = true
    // },
    // 新增
    addLines() {
      this.linePopupType = 'set'
      this.centerDialogVisible = true
    },
    // 下拉加载更多
    divScrollChange() {
      let scroll = document.getElementById('scroll'),
        scrollTop = this.$refs.scrollTopList.scrollTop,
        clientHeight = this.$refs.scrollTopList.clientHeight,
        scrollHeight = this.$refs.scrollTopList.scrollHeight,
        height = this.$refs.scrollTopList.offsetHeight
      let that = this
      scroll.addEventListener('scroll', function (e) {
        if (scrollTop + clientHeight >= scrollHeight - height) {
          if (that.searchForm.pageSize > that.total) {
            return false
          } else {
            that.searchForm.pageSize = that.searchForm.pageSize + 8
            that.getAmounttsList()
          }
        } else {
          return false
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.SetLine {
  padding-right: 1.125rem;
}
.lineCard-show-box {
  padding-top: 20px;
  height: calc(100vh - 250px);
  overflow-y: overlay;
}
.type-name {
  width: 80px;
}
.line-in-button {
  width: 100%;
}
.button-left {
  width: 100%;
  color: #333333;
  background: #f2f3f8;
}
.button-right {
  width: 100%;
  color: #ffffff;
  background: #637dff;
}
.font-refresh-16 {
  font-size: 14px;
  color: #637dff;
}
.df-item {
  font-size: 14px;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  text-align: left;
  color: #09132d;
  letter-spacing: 0px;
  margin: 0 20px;
  cursor: pointer;
  &.active {
    color: #637dff;
  }
}
.flex {
  display: flex;
}
.configtext {
  font-size: 14px;
  font-family: PingFang SC, PingFang SC-Bold;
  font-weight: 700;
  text-align: left;
  color: #09132d;
}
.append-margin {
  margin-top: 15px;
}
::v-deep(.header-row th) {
  background: #eeeef3;
}
.button-text-edit {
  color: #637dff;
}
.button-text-del {
  color: #ff5757;
}
</style>